/*=========================
	All css
==========================*/
@import url("../plugins/bootstrap/css/bootstrap.min.css");
@import url("../plugins/flaticon/flaticon.css");
@import url("../plugins/fontawesome/css/font-awesome.min.css");
@import url("../plugins/line-awesome/css/line-awesome.min.css");
@import url("../plugins/themify/themify-icons.css");
@import url("../plugins/bootstrap-select/bootstrap-select.min.css");
@import url("../plugins/animate/animate.css");
/*=========================
	All css
==========================*/
@import url('https://fonts.googleapis.com/css?family=Montserrat:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i|Open+Sans:300,300i,400,400i,600,600i,700,700i,800,800i|Oswald:200,300,400,500,600,700|Poppins:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i|Roboto:100,100i,300,300i,400,400i,500,500i,700,700i,900,900i');
@import url('https://fonts.googleapis.com/css?family=Abhaya+Libre:400,500,600,700,800');
@import url('https://fonts.googleapis.com/css?family=Lora:400,400i,700,700i');
/* 
    font-family: 'Roboto', sans-serif;
    font-family: 'Poppins', sans-serif;
    font-family: 'Montserrat', sans-serif;
    font-family: 'Oswald', sans-serif;
    font-family: 'Open Sans', sans-serif;
	font-family: 'Lora', serif;
	font-family: 'Abhaya Libre', serif;
*/